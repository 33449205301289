<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"></slot>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">{{ dialogTitle }}</span>
      </v-card-title>
      <v-card-text>
        <form class="tw-w-full tw-flex tw-flex-col">
          <v-col cols="12">
            <v-select
              v-model="selected_resources"
              :items="resources"
              label="Seleziona risorse"
              multiple
              outlined
              :disabled="isMaster || selected_resources.length > 0"
              :rules="[(v) => !!v.length || 'Seleziona almeno una risorsa']"
            ></v-select>
          </v-col>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Quantità*"
                required
                v-model="requested_qty"
                type="number"
                :disabled="isMaster"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Onda*"
                required
                v-model="material_onda"
                :disabled="isMaster"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                label="Copertina Est.*"
                required
                outlined
                v-model="material_copertina_est"
                :items="['Bianca', 'Avana', 'Patinata', 'Indifferente']"
                :disabled="isMaster"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                label="Copertina Int.*"
                required
                v-model="material_copertina_int"
                :items="['Bianca', 'Avana', 'Patinata', 'Indifferente']"
                outlined
                :disabled="isMaster"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Data di consegna*"
                required
                v-model="delivery_date"
                type="date"
                :disabled="isMaster"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="palletizzazione"
                :items="pallet"
                label="Seleziona palletizzazione"
                outlined
                :disabled="isMaster"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="legato"
                label="Legato a pacchi"
                value="Legato a pacchi"
                :disabled="isMaster"
              ></v-checkbox>
              <v-checkbox
                v-model="legato"
                label="Film Estensibile"
                value="Film Estensibile"
                :disabled="isMaster"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" v-if="palletizzazione === 'Su bancale'">
              <v-select
                v-model="bancale"
                :items="bancali"
                label="Seleziona tipo di bancale"
                outlined
                required
                :disabled="isMaster"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="dxf_files"
                label="Carica file DXF"
                multiple
                outlined
                :disabled="!isMaster"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Note"
                outlined
                v-model="notes_prod"
                :disabled="isMaster"
              ></v-textarea>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Annulla</v-btn>
        <v-btn color="blue darken-1" text @click="confirmProduction">
          {{ actionButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    production: {
      type: Object,
      default: null,
    },
    isMaster: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      requested_qty: null,
      material_onda: "",
      material_copertina_est: "",
      material_copertina_int: "",
      delivery_date: "",
      palletizzazione: "",
      legato: [],
      bancale: "",
      notes_prod: "",
      pallet: ["Su bancale", "Master a croce", "Americana 60x40", "Nessuna"],
      bancali: [        
        "Generico 80x120",
        "Generico 100x120",
        "EPAL Prima Scelta",
        "EPAL Seconda Scelta",
        "Bancale fuori misura",],
      selected_resources: [],
      resources: ["PLOTTER", "PLATINA", "AUTOPLATINA", "LAVORAZIONI MANUALI"],
      dxf_files: [],
    };
  },
  computed: {
    dialogTitle() {
      return this.production ? "Modifica Produzione" : "Crea Produzione Interna";
    },
    actionButtonText() {
      return this.production ? "Aggiorna Produzione" : "Crea Produzione";
    },
  },
  watch: {
    production: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.requested_qty = newVal.requested_qty;
          this.material_onda = newVal.material_onda;
          this.material_copertina_est = newVal.material_copertina_est;
          this.material_copertina_int = newVal.material_copertina_int;
          this.delivery_date = newVal.delivery_date;
          this.palletizzazione = newVal.palletizzazione;
          this.legato = newVal.legato ? JSON.parse(newVal.legato) : [];
          this.bancale = newVal.bancale;
          this.notes_prod = newVal.notes_prod;
          this.selected_resources = Object.keys(
            newVal.manufactures ? newVal.manufactures : {}
          );
        }
      },
    },
  },
  methods: {
    validateForm() {
      if (this.selected_resources.length == 0) {
        this.$store.dispatch("notificate", {
          text: "Seleziona almeno una risorsa",
          type: "warning",
        });
        return false;
      }
      if (!this.requested_qty) {
        this.$store.dispatch("notificate", {
          text: "Inserisci la quantita`",
          type: "warning",
        });
        return false;
      }

      return true
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async confirmProduction() {
      if (!this.validateForm()) return;

      const productionData = {
        requested_qty: this.requested_qty,
        material_onda: this.material_onda,
        material_copertina_est: this.material_copertina_est,
        material_copertina_int: this.material_copertina_int,
        delivery_date: this.delivery_date,
        palletizzazione: this.palletizzazione,
        legato: JSON.stringify(this.legato),
        bancale: this.bancale,
        notes_prod: this.notes_prod,
        selected_resources: this.selected_resources,
        project_id: this.projectId,
      };

      try {
        if (this.production) {
          // Aggiorna la produzione esistente
          await axios.put(
            `/stock-productions/${this.production.id_prod}`,
            productionData
          );
        } else {
          // Crea una nuova produzione
          const response = await axios.post(
            "/stock-productions/internal",
            productionData
          );
          this.$store.dispatch("notificate", {
            text: "Produzione aggiornata con successo",
            type: "success",
          });
          this.$emit("production-created", response.data.id_prod);
        }

        if (this.isMaster && this.dxf_files.length > 0) {
          // Carica i file DXF
          for (const file of this.dxf_files) {
            const formData = new FormData();
            formData.append("file", file);
            await axios.post(
              `/stock-productions/${this.production.id_prod}/upload-dxf`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            this.$store.dispatch("notificate", {
              text: "File tecnici caricati con successo",
              type: "success",
            });
          }
        }

        this.closeDialog();
        this.$emit("production-updated");
      } catch (error) {
        console.error("Errore durante l'operazione:", error);
      }
    },
  },
};
</script>